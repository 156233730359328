import { useEffect } from "react";

import { apiBase } from "../../config";

const FilesPage = ({ params: { path } }) => {
    useEffect(
        () =>
            (window.location.href = new URL(
                `files/${path}`,
                apiBase
            ).toString()),
        [path]
    );

    return null;
};
export default FilesPage;
